<template>
  <section class="modal-confirmation">
    <Modal :use-icon-close="false">
      <div class="modal-confirmation-body">
        <img
          src="/images/confirm_illustration.svg"
          alt="confirm icon"
        >
        <span class="modal-confirmation-body__title">Data yang Dikirim Sudah Sesuai?</span>
        <span class="modal-confirmation-body__subtitle">Pastikan data yang Anda kirim telah benar & sesuai</span>
      </div>
      <div class="modal-confirmation-buttons">
        <Button
          text="Cek Kembali"
          type-class="outline-red"
          @click="close"
        />
        <Button
          text="Ya, Sesuai"
          @click="submit"
        />
      </div>
    </Modal>
  </section>
</template>
<script>
import Modal from "@/components/new-modal/Modal.vue";
import Button from "@/components/new-button/Button.vue";

export default {
  components: {Modal, Button},
  methods: {
    close() {
      this.$emit('close')
    },
    submit() {
      this.$emit('submit')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.modal-confirmation {
  & ::v-deep .modal-lp__content {
    box-shadow: 0px 1px 4px rgba(26, 20, 33, 0.15);
    border-radius: 16px;
    
    & .modal-lp__content__body {
      padding: 24px;
    }
  }
}

.modal-confirmation-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  width: 353px;
  height: 179px;

  @include for-size(mobile) {
    width: 256px;
  }

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $color-base-text;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $color-base-text;
  }
}

.modal-confirmation-buttons {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  & ::v-deep .custom-btn {
    &:first-child {
      margin-right: 16px;
    }

    & div {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

</style>
