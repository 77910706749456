<template>
  <section class="career-success-form">
    <div class="career-success-form__container">
      <img
        src="/images/success-register.svg"
        alt="success career form"
      >
      <div class="career-success-form__container__text">
        <span class="career-success-form__container__text__title">Lamaran Anda Telah Terkirim</span>
        <span class="career-success-form__container__text__subtitle">Tim kami akan menghubungi Anda melalui email, silahkan cek email Anda
          secara berkala</span>
      </div>
      <div class="career-success-form__container__button">
        <Button
          text="Ke Halaman Utama"
          type-class="outline-red"
          @click="goToHome"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Button from "@/components/new-button/Button.vue";

export default {
  components: { Button },
  methods: {
    goToHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.career-success-form {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70vh;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 500px;

    @include for-size(mobile) {
      max-width: 320px;
    }

    &__text {
      margin-top: 24px;
      display: flex;
      flex-direction: column;

      &__title {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: $color-base-text;
      }
      
      &__subtitle {
        margin-top: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: $color-gray-shade;
      }
    }

    &__button {
      margin-top: 24px;
    }
  }
}
</style>
