<template>
  <div
    v-if="isLoading"
    class="loader-container"
  >
    <Loader color-class="l-grey" />
  </div>
  <div v-else-if="career.status === 'ACTIVE'">
    <div
      v-if="isLoadingSubmit"
      class="loader-career-form"
    >
      <div class="loader-career-form__box">
        <div>
          <Loader
            color-class="l-grey"
            position-left="32%"
          />
        </div>
      </div>
    </div>
    <section
      v-if="!submited"
      class="career-form"
    >
      <div class="career-form__breadcrumb">
        <BreadCrumb :is-use-route-name="true" />
      </div>
      <div class="career-form__container">
        <div class="career-form__container__card">
          <div
            v-if="career.career_id"
            class="career-form__container__card__title"
          >
            <span>{{ career.job_position }}</span>
            <div class="career-form__container__card__title__position">
              <div
                class="career-form__container__card__title__position__status"
                :style="
                  `background: ${
                    decideLabelStatus(career.employment_status).color
                  }; color: ${
                    decideLabelStatus(career.employment_status).fontColor
                  }`
                "
              >
                <span>
                  {{ decideLabelStatus(career.employment_status).label }}
                </span>
              </div>
              <img
                src="/images/eclipse-divider.svg"
                alt="divider icon"
              >
              <div
                class="career-form__container__card__title__position__label-icon"
              >
                <img
                  src="/images/ics_f_group_two.svg"
                  alt="group icon"
                  height="12px"
                  width="12px"
                >
                <span>{{ career.division_name }}</span>
              </div>
              <img
                src="/images/eclipse-divider.svg"
                alt="divider icon"
              >
              <div
                class="career-form__container__card__title__position__label-icon"
              >
                <img
                  src="/images/ics_f_location_pin.svg"
                  alt="location icon"
                  height="12px"
                  width="12px"
                >
                <span>{{ career.city }}</span>
              </div>
            </div>
          </div>
          <div class="career-form__container__card__form">
            <div
              class="career-form__container__card__form__input"
              :class="form.candidate_name.error && 'career-input-error'"
            >
              <Input
                v-model="form.candidate_name.value"
                label="Nama Lengkap"
                name="candidate_name"
                placeholder="Masukkan nama Anda"
                :is-required="true"
                :error-message="form.candidate_name.errorMessage"
                :is-error="form.candidate_name.error"
                @input="() => onInput('candidate_name')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="form.candidate_email.error && 'career-input-error'"
            >
              <Input
                v-model="form.candidate_email.value"
                label="Email"
                name="candidate_email"
                placeholder="Masukkan email Anda"
                :is-required="true"
                :error-message="form.candidate_email.errorMessage"
                :is-error="form.candidate_email.error"
                @input="() => onInput('candidate_email')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="
                form.candidate_date_of_birth.error && 'career-input-error'
              "
            >
              <DatePicker
                v-model="form.candidate_date_of_birth.value"
                label="Tanggal Lahir"
                name="candidate_date_of_birth"
                placeholder="Masukkan tanggal lahir Anda"
                :required="true"
                :max-date="new Date()"
                :error-message="form.candidate_date_of_birth.errorMessage"
                :is-error="form.candidate_date_of_birth.error"
                @input="() => onInput('candidate_date_of_birth')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="form.candidate_gender.error && 'career-input-error'"
            >
              <Radio
                v-model="form.candidate_gender.value"
                label="Jenis Kelamin"
                :options="[
                  { value: 'laki-laki', label: 'Laki-Laki' },
                  { value: 'perempuan', label: 'Perempuan' },
                ]"
                name="candidate_gender"
                :is-required="true"
                :error-message="form.candidate_gender.errorMessage"
                :is-error="form.candidate_gender.error"
                @input="() => onInput('candidate_gender')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="form.candidate_phone_number.error && 'career-input-error'"
            >
              <Input
                v-model="form.candidate_phone_number.value"
                label="No. Handphone"
                name="candidate_phone_number"
                placeholder="Masukkan Nomor handphone dan diawali angka 8"
                :is-required="true"
                prefix="+62"
                :error-message="form.candidate_phone_number.errorMessage"
                :is-error="form.candidate_phone_number.error"
                note="Pastikan nomor handphone tersambung ke WA"
                @input="() => onInput('candidate_phone_number')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="form.candidate_place.error && 'career-input-error'"
            >
              <Dropdown
                v-model="form.candidate_place.value"
                label="Kota Domisili"
                name="candidate_place"
                placeholder="Pilih kota domisili"
                :options="placeOptions"
                :is-required="true"
                :error-message="form.candidate_place.errorMessage"
                :is-error="form.candidate_place.error"
                @input="() => onInput('candidate_place')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="
                form.candidate_latest_education.error && 'career-input-error'
              "
            >
              <Dropdown
                v-model="form.candidate_latest_education.value"
                label="Pendidikan Terakhir"
                name="candidate_latest_education"
                placeholder="Pilih pendidikan terakhir"
                :is-required="true"
                :options="lastEducationOptions"
                :error-message="form.candidate_latest_education.errorMessage"
                :is-error="form.candidate_latest_education.error"
                @input="() => onInput('candidate_latest_education')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="
                form.candidate_formal_education.error && 'career-input-error'
              "
            >
              <Input
                v-model="form.candidate_formal_education.value"
                label="Universitas atau Sekolah"
                name="candidate_formal_education"
                placeholder="Masukkan Jurusan & Universitas/Sekolah Anda"
                note="Contoh: Psikologi - UGM atau IPA - SMAN 2 Medan"
                :is-required="true"
                :error-message="form.candidate_formal_education.errorMessage"
                :is-error="form.candidate_formal_education.error"
                @input="() => onInput('candidate_formal_education')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="form.candidate_referrer.error && 'career-input-error'"
            >
              <Dropdown
                v-model="form.candidate_referrer.value"
                label="Darimana Anda Mendapatkan Informasi Lowongan Ini?"
                name="candidate_referrer"
                placeholder="Pilih sumber informasi"
                :is-required="true"
                :options="sourceOptions"
                :error-message="form.candidate_referrer.errorMessage"
                :is-error="form.candidate_referrer.error"
                @input="() => onInput('candidate_referrer')"
              />
            </div>
            <div
              class="career-form__container__card__form__input"
              :class="form.attachment_resume_url.error && 'career-input-error'"
            >
              <FileUpload
                v-model="form.attachment_resume_url.value"
                label="CV"
                :required="true"
                title="Upload CV"
                subtitle="Format .pdf sampai 5 MB"
                :file-formats="['.pdf']"
                :error-message="form.attachment_resume_url.errorMessage"
                :is-error="form.attachment_resume_url.error"
                @input="() => onInput('attachment_resume_url')"
                @click="() => clearError('attachment_resume_url')"
                @error="() => setFileUploadError('attachment_resume_url')"
              />
            </div>
            <div class="career-form__container__card__form__input">
              <FileUpload
                v-model="form.attachment_portfolio_url.value"
                label="Portofolio"
                title="Upload Portofolio"
                subtitle="Format .rar .zip .pdf sampai 16 MB"
                :optional="true"
                :file-formats="['.pdf', '.rar', '.zip']"
                :is-error="form.attachment_portfolio_url.error"
                :error-message="form.attachment_portfolio_url.errorMessage"
                @input="() => onInput('attachment_portfolio_url')"
                @click="() => clearError('attachment_portfolio_url')"
                @error="() => setFileUploadError('attachment_portfolio_url')"
              />
            </div>
            <div class="career-form__container__card__form__button">
              <Button
                text="Kirim Lamaran"
                @click="validateForm"
              />
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmation
        v-if="isOpenConfirmationPopUp"
        @close="closeModal"
        @submit="submitForm"
      />
    </section>
    <Success v-if="submited" />
  </div>
  <div v-else>
    <NotFound />
  </div>
</template>
<script>
import BreadCrumb from "@/components/new-breadcrumb/BreadCrumb.vue";
import Input from "@/pages/new-career/components/Input.vue";
import DatePicker from "@/components/datepicker/DatePicker.vue";
import NotFound from "@/pages/not-found/NotFound.vue";
import Loader from "@/components/new-loader/Loader.vue";
import Radio from "@/pages/new-career/components/Radio.vue";
import Dropdown from "@/pages/new-career/components/Dropdown.vue";
import FileUpload from "@/components/file-upload/FileUpload.vue";
import ModalConfirmation from "@/pages/new-career/components/ModalConfirmation.vue";
import Button from "@/components/new-button/Button.vue";
import Success from "@/pages/new-career/form/Success.vue";
import CareerUseCase from "@/pages/new-career/app/usecase/career";
import decideLabelStatus from "../app/helpers/career-label";
import {
  lastEducationOptions,
  placeOptions,
  sourceOptions,
  mapOptions,
} from "../app/misc/careerDropdownOptions";

export default {
  components: {
    BreadCrumb,
    Input,
    DatePicker,
    Radio,
    Dropdown,
    FileUpload,
    ModalConfirmation,
    Button,
    Success,
    NotFound,
    Loader,
  },
  data() {
    return {
      decideLabelStatus,
      placeOptions: mapOptions(placeOptions),
      lastEducationOptions: mapOptions(lastEducationOptions),
      sourceOptions: mapOptions(sourceOptions),
      career: {},
      isOpenConfirmationPopUp: false,
      isLoading: true,
      isLoadingSubmit: false,
      form: {
        career_id: {
          value: null,
        },
        candidate_name: {
          value: "",
          errorMessage: "Nama Lengkap harus diisi",
          type: "text",
          error: false,
        },
        candidate_email: {
          value: "",
          errorMessage: "Email harus diisi",
          type: "email",
          error: false,
        },
        candidate_date_of_birth: {
          value: null,
          errorMessage: "Tanggal Lahir harus diisi",
          type: "date",
          error: false,
        },
        candidate_gender: {
          value: "",
          errorMessage: "Jenis Kelamin harus diisi",
          type: "text",
          error: false,
        },
        candidate_phone_number: {
          value: "",
          errorMessage: "No. Handphone harus diisi",
          type: "phone",
          error: false,
        },
        candidate_place: {
          value: [],
          errorMessage: "Kota Domisili harus diisi",
          type: "dropdown",
          error: false,
        },
        candidate_latest_education: {
          value: [],
          errorMessage: "Pendidikan Terakhir harus diisi",
          type: "dropdown",
          error: false,
        },
        candidate_formal_education: {
          value: "",
          errorMessage: "Universitas atau Sekolah harus diisi",
          type: "text",
          error: false,
        },
        candidate_referrer: {
          value: [],
          errorMessage:
            "Darimana Anda Mendapatkan Informasi Lowongan Ini harus diisi",
          type: "dropdown",
          error: false,
        },
        attachment_resume_url: {
          value: null,
          errorMessage: "CV harus diisi",
          type: "file",
          error: false,
        },
        attachment_portfolio_url: {
          value: null,
          error: false,
        },
      },
      submited: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getCareerDetail();
  },
  methods: {
    /**
     * to get detail career by id
     */
    async getCareerDetail() {
      this.isLoading = true;
      const { data, err } = await CareerUseCase.getDetail(
        this.$route.params.career_id
      );
      if (data) {
        this.career = data;
        this.form.career_id.value = this.career.career_id;
      }
      if (err) {
        this.career.status = "INACTIVE";
      }
      this.isLoading = false;
    },
    /**
     * to validate form when submit
     */
    async validateForm() {
      await this._checkTextInputValue();
      await this._checkDropDownValue();
      this._checkEmailValue();
      this._checkFileValue();
      this._checkPhoneNumberValue();

      if (this.checkEmptyValue()) {
        this.scrollToErrorField();
      } else {
        this.isOpenConfirmationPopUp = true;
      }
    },
    /**
     * to check input value with type text, will set error when value is empty
     * @returns {Promise<void>}
     */
    _checkTextInputValue() {
      return new Promise((resolve) => {
        Object.keys(this.form).forEach((key, i) => {
          if (
            this.form[key].type === "text" ||
            this.form[key].type === "date"
          ) {
            if (!this.form[key].value) {
              this.form[key].error = true;
            }
          }

          if (i === Object.keys(this.form).length - 1) {
            resolve();
          }
        });
      });
    },
    /**
     * to check dropdown value, will set error when dropdown value is empty
     * @returns {Promise<Void>}
     */
    _checkDropDownValue() {
      return new Promise((resolve) => {
        Object.keys(this.form).forEach((key, i) => {
          if (this.form[key].type === "dropdown") {
            if (!this.form[key].value.length) {
              this.form[key].error = true;
            }
          }

          if (i === Object.keys(this.form).length - 1) {
            resolve();
          }
        });
      });
    },
    /**
     * to check if format email not valid, will set error email not valid, if email value is empty, will set error is empty
     * @returns {Void}
     */
    _checkEmailValue() {
      if (this.form.candidate_email.value) {
        if (!this._validateEmail(this.form.candidate_email.value)) {
          this.form.candidate_email.errorMessage =
            "Format yang anda masukan salah";
          this.form.candidate_email.error = true;
        }
      } else {
        this.form.candidate_email.error = true;
        this.form.candidate_email.errorMessage = "Email harus diisi";
      }
    },
    _checkFileValue() {
      if (!this.form.attachment_resume_url.value) {
        this.form.attachment_resume_url.error = true;
      }
    },
    /**
     * validate email format
     * @param {String} email email
     */
    _validateEmail(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    /**
     * to check if phone number is empty, will set error phone number is empty.
     * otherwise if phone number digit is less than 9 and more than 12, will set error phone number length
     * @returns {Void}
     */
    _checkPhoneNumberValue() {
      if (this.form.candidate_phone_number.value) {
        if (
          this.form.candidate_phone_number.value.length < 9 ||
          this.form.candidate_phone_number.value.length > 12
        ) {
          this.form.candidate_phone_number.errorMessage =
            "Panjang karakter antara 9-12 angka";
          this.form.candidate_phone_number.error = true;
        }
      } else {
        this.form.candidate_phone_number.errorMessage =
          "No. Handphone harus diisi";
        this.form.candidate_phone_number.error = true;
      }
    },
    /**
     * to scroll to first field error element
     * @returns {Void}
     */
    scrollToErrorField() {
      let element = document.querySelector(".career-input-error");

      if (element) {
        let headerOffset = 90;
        let elementPosition = element.getBoundingClientRect().top;
        let offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
    /**
     * to check all form is contain error
     * @returns {Boolean}
     */
    checkEmptyValue() {
      return Object.keys(this.form)
        .map((key) => {
          return this.form[key].error;
        })
        .includes(true);
    },
    /**
     * to prevent initial digit is not 8
     * @returns {Void}
     */
    _checkPhoneNumberInput(key) {
      if (key === "candidate_phone_number" && this.form[key].value[0] !== "8") {
        this.form[key].value = null;
        document.querySelector(`input[name="${key}"]`).value = "";
      }
    },
    /**
     * to validate candidate cv file value
     * @param {String} key form key
     */
    _checkCVInput(key) {
      if (
        key === "attachment_resume_url" &&
        this._checkFileMaxSize(this.form[key].value.size, 5)
      ) {
        this.form[key].error = true;
        this.form[key].errorMessage = "CV tidak boleh lebih dari 5MB";
        this.form[key].value = null;
      }
    },
    /**
     * to validate candidate portofolio file value
     * @param {String} key form key
     */
    _checkPortofolioInput(key) {
      if (
        key === "attachment_portfolio_url" &&
        this._checkFileMaxSize(this.form[key].value.size, 16)
      ) {
        this.form[key].error = true;
        this.form[key].errorMessage = "Portofolio tidak boleh lebih dari 16MB";
        this.form[key].value = null;
      }
    },
    /**
     * to check max file size
     * @param {number} size file input size
     * @param {number} maxSize max size to compare
     */
    _checkFileMaxSize(size, maxSize) {
      return size > 1024 * 1024 * maxSize;
    },
    onInput(key) {
      if (this.form[key].value) {
        this.form[key].error = false;
        this._checkPhoneNumberInput(key);
        this._checkCVInput(key);
        this._checkPortofolioInput(key);
      }
    },
    /**
     * to set error by file upload component emit
     * @param {String} key form key
     */
    setFileUploadError(key) {
      if (key == "attachment_portfolio_url") {
        this.form[key].errorMessage =
          "File yang diupload harus berformat .rar, .zip, .pdf";
        this.form[key].error = true;
      }
      if (key === "attachment_resume_url") {
        this.form[key].errorMessage = "File yang diupload harus berformat .pdf";
        this.form[key].error = true;
      }
    },
    /**
     * to clear field error
     * @param {String} key form key
     */
    clearError(key) {
      this.form[key].error = false;
    },
    closeModal() {
      this.isOpenConfirmationPopUp = false;
    },
    async submitForm() {
      this.isOpenConfirmationPopUp = false;
      this.isLoadingSubmit = true;
      const { data, err } = await CareerUseCase.submitCandidate(this.form);

      if (data && data.candidate_id) {
        this.submited = true;
        window.scrollTo(0, 0);
      }

      if (err) {
        alert(err);
      }

      this.isLoadingSubmit = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
