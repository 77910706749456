<template>
  <section class="file-upload">
    <span
      class="file-upload__label"
      :class="required ? 'required' : optional ? 'optional' : ''"
    >{{ label }}</span>
    <div
      v-if="!value"
      class="file-upload__input-box"
      :class="isError && 'error'"
      @click="uploadFile"
    >
      <img
        class="file-upload__input-box__upload-icon"
        src="/images/UploadIcon.svg"
        alt="upload icon"
      >
      <span class="file-upload__input-box__title">
        {{ title }}
      </span>
      <span class="file-upload__input-box__sub-title">
        {{ subtitle }}
      </span>
    </div>
    <div
      v-if="value"
      class="file-upload__input-box filled"
    >
      <div class="file-upload__input-box__attachment">
        <img
          class="file-upload__input-box__attachment__icon"
          src="/images/ics_o_attach.svg"
          alt="attach icon"
        >
        <span class="file-upload__input-box__attachment__name">
          {{ label }} - {{ getPreviewFile() }}
        </span>
      </div>
      <div
        class="file-upload__input-box__button"
        @click="uploadFile"
      >
        Ubah Berkas
      </div>
    </div>
    <input
      ref="input-file"
      style="display: none;"
      type="file"
      :accept="fileFormats.join(', ')"
      @change="onChangeFile"
    >
    <div class="file-upload__messages">
      <div
        v-show="isError"
        class="file-upload__messages__error"
      >
        <img
          src="/images/icon_danger_triangles.svg"
          alt="warning icon"
        >
        <p v-html="errorMessage" />
      </div>
    </div>
  </section>
</template>
<script>
import { isSsr } from '../../helpers/DetectSSR';
export default {
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    value: {
      type: isSsr() ? String : File,
      default: null
    },
    fileFormats: {
      type: Array,
      default: () => ["image/png"],
    },
    required: {
      type: Boolean,
      default: false
    },
    optional: {
      type: Boolean,
      default: false
    },
    isError: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ""
    }
  },
  methods: {
    uploadFile() {
      this.$refs["input-file"].value = null;
      this.$refs["input-file"].click();
      this.$emit('click');
    },
    onChangeFile(e) {
      if (e.target.files[0] && this.validateFileFormat(e.target.files[0])) {
        this.$emit("input", e.target.files[0]);
      } else {
        this.$emit("error", "Format File Tidak Sesuai")
      }
    },
    removeFile() {
      this.$emit("input", null);
    },
    getPreviewFile() {
      return this.value?.name
    },
    validateFileFormat(file) {
      return this.fileFormats
        .map((format) => {
          return !!file.type.match(format);
        })
        .includes(true);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
