export const placeOptions = [
  "Jakarta Utara",
  "Jakarta Barat",
  "Jakarta Pusat",
  "Jakarta Timur",
  "Jakarta Selatan",
  "Bogor",
  "Bekasi",
  "Depok",
  "Tangerang",
  "Other",
]

export const lastEducationOptions = [
  "SMA",
  "SMK",
  "D1",
  "D2",
  "D3",
  "S1",
  "S2",
  "S3",
]

export const sourceOptions = [
  "LinkedIn",
  "Tech in Asia",
  "E-mail",
  "Social Media",
  "Career Page",
  "Glints"
]

export const mapOptions = (options) => {
  return options.map((option) => ({
    label: option,
    value: option
  }))
}
