<template>
  <section
    v-click-outside="closeCalendar"
    class="datepicker"
  >
    <div
      class="datepicker__input"
      @click="openCalendar"
    >
      <span
        class="datepicker__input__label"
        :class="required && 'required'"
      >
        {{ label }}
      </span>
      <Input
        :name="name"
        :placeholder="placeholder"
        :value="dateToString"
        :custom-class="'prefix ' + name"
      >
      <template #icon>
        <img
          class="calendar-icon"
          src="/images/ics_o_calendar_grey.svg"
          alt="calendar icon"
        >
      </template>
      </Input>
      <div class="datepicker__input__messages">
        <div
          v-show="isError"
          class="datepicker__input__messages__error"
        >
          <img
            src="/images/icon_danger_triangles.svg"
            alt="warning icon"
          >
          <p v-html="errorMessage" />
        </div>
      </div>
    </div>
    <div
      v-if="isCalendarOpen"
      class="datepicker__calendar"
    >
      <div class="datepicker__calendar__title">
        {{ title }}
      </div>
      <div class="datepicker__calendar__month">
        <img
          src="/images/chevron-double-left.svg"
          alt="prev icon"
          @click="prevYear"
        >
        <div class="datepicker__calendar__month__center">
          <img
            src="/images/chevron-left.svg"
            alt="prev icon"
            @click="prevDate"
          >
          <span>{{ months[currentMonth] }} {{ dateInit.getFullYear() }}</span>
          <img
            src="/images/chevron-right.svg"
            alt="prev icon"
            @click="nextDate"
          >
        </div>
        <img
          src="/images/chevron-double-right.svg"
          alt="prev icon"
          @click="nextYear"
        >
      </div>
      <div class="datepicker__calendar__day">
        <div
          v-for="dayName in days"
          :key="dayName"
        >
          {{ dayName }}
        </div>
      </div>
      <div class="datepicker__calendar__date">
        <div
          v-for="index in firstDayIndex"
          :key="`firstDayIndex_${index}`"
        />
        <div
          v-for="index in lastDay"
          :key="`lastDay_${index}`"
          :class="decideDateStyle(index, dateInit, lastDay)"
          @click="() => selectDate(index, dateInit)"
        >
          {{ index }}
        </div>
        <div
          v-for="index in nextDays"
          :key="`nextDays__${index}`"
        />
      </div>
      <div class="datepicker__calendar__footer">
        <Button
          text="Pilih"
          :disabled="decideDisabledButton"
          @click="submit"
        />
      </div>
    </div>
  </section>
</template>
<script>
import Input from '@/components/new-input/Input.vue';
import Button from '@/components/new-button/Button';
import MixinMobile from '@/misc/mixinMobile';
import { isSsr } from '@/helpers/DetectSSR.js';

export default {
  components: { Input, Button },
  directives: {
    'click-outside': {
      bind(el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        if (!isSsr()) {
          document.body.addEventListener('click', el.clickOutsideEvent);
        }
      },
      unbind(el) {
        if (!isSsr()) {
          document.body.removeEventListener('click', el.clickOutsideEvent);
        }
      },
    },
  },
  mixins: [MixinMobile],
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Date,
      default: null,
    },
    maxDate: {
      type: Date,
      default: null,
    },
    minDate: {
      type: Date,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Masukan tanggal lahir anda',
    },
    title: {
      type: String,
      default: 'Pilih Tanggal',
    },
    required: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCalendarOpen: false,
      selectedDate: null,
      dateInit: new Date(),
      firstDayIndex: 0,
      lastDayIndex: 0,
      lastDay: 0,
      nextDays: 0,
      currentMonth: 0,
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'Mei',
        'Jun',
        'Jul',
        'Agu',
        'Sep',
        'Okt',
        'Nov',
        'Des',
      ],
      fullmonths: [
        'Januari',
        'Februari',
        'Maret',
        'April',
        'Mei',
        'Juni',
        'Juli',
        'Agustus',
        'September',
        'Oktober',
        'November',
        'Desember',
      ],
      days: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    };
  },
  computed: {
    decideDisabledButton() {
      return !this.selectedDate;
    },
    dateToString() {
      return this.converDateToString();
    },
  },
  created() {
    this.dateInit.setDate(1);
    this.dateInit.setMonth(this.dateInit.getMonth());
    this.currentMonth = this.dateInit.getMonth();
    this.setDate();
  },
  mounted() {
    this.decideErrorField();
  },
  updated() {
    this.decideErrorField();
  },
  methods: {
    setDate() {
      this.dateInit.setDate(1);
      this.lastDay = new Date(
        this.dateInit.getFullYear(),
        this.dateInit.getMonth() + 1,
        0,
      ).getDate();
      this.firstDayIndex = this.dateInit.getDay();
      this.lastDayIndex = new Date(
        this.dateInit.getFullYear(),
        this.dateInit.getMonth() + 1,
        0,
      ).getDay();
      this.nextDays = 7 - this.lastDayIndex - 1;
    },
    nextDate() {
      this.currentMonth++;
      let tempDate = new Date(this.dateInit.toDateString());
      tempDate = this._calculateNextYearDate(tempDate);
      this.dateInit = tempDate;
      this.setDate();
    },
    nextYear() {
      const tempDate = new Date(this.dateInit.toDateString());
      tempDate.setFullYear(tempDate.getFullYear() + 1);
      this.dateInit = tempDate;
      this.setDate();
    },
    _calculateNextYearDate(tempDate) {
      if (this.currentMonth === 12) {
        tempDate.setFullYear(tempDate.getFullYear() + 1, 0, 1);
        this.currentMonth = 0;
      } else {
        tempDate.setDate(1);
        tempDate.setMonth(this.currentMonth);
      }

      return tempDate;
    },
    prevDate() {
      this.currentMonth--;
      let tempDate = new Date(this.dateInit.toDateString());
      tempDate = this._calculatePrevYearDate(tempDate);
      this.dateInit = tempDate;
      this.setDate();
    },
    prevYear() {
      const tempDate = new Date(this.dateInit.toDateString());
      tempDate.setFullYear(tempDate.getFullYear() - 1);
      this.dateInit = tempDate;
      this.setDate();
    },
    _calculatePrevYearDate(tempDate) {
      if (this.currentMonth === -1) {
        tempDate.setFullYear(tempDate.getFullYear() - 1, 11, 1);
        this.currentMonth = 11;
      } else {
        tempDate.setDate(1);
        tempDate.setMonth(this.currentMonth);
      }

      return tempDate;
    },
    decideDateStyle(date, month) {
      month.setDate(date);
      month.setHours(0, 0, 0, 0);
      if (this._decideSelectedDateStyle(month)) {
        return this._decideSelectedDateStyle(month);
      }
      if (this._decideTodayDateStyle(date, month)) {
        return this._decideTodayDateStyle(date, month);
      }
      if (this._decideMaxDateStyle(date, month)) {
        return this._decideMaxDateStyle(date, month);
      }
    },
    _decideSelectedDateStyle(month) {
      if (
        this.selectedDate
          && month.toISOString() === this.selectedDate.toISOString()
      ) {
        return 'selected';
      }
    },
    _decideTodayDateStyle(date, month) {
      if (
        date === new Date().getDate()
        && month.getMonth() === new Date().getMonth()
        && month.getFullYear() === new Date().getFullYear()
      ) {
        return 'today';
      }
    },
    _decideMaxDateStyle(date, month) {
      const tempMonth = month;
      tempMonth.setDate(date);
      if (this.maxDate) {
        const tempMaxDate = this.maxDate;
        tempMaxDate.setHours(0, 0, 0, 0);
        if (tempMonth > tempMaxDate) {
          return 'disabled';
        }
      }
    },
    converDateToString() {
      if (this.value) {
        const day = new Date(this.value).getDate();
        const month = new Date(this.value).getMonth();
        const year = new Date(this.value).getFullYear();

        return `${day} ${this.fullmonths[month]} ${year}`;
      }
    },
    selectDate(date, month) {
      const value = new Date(month.setDate(date));
      value.setHours(0, 0, 0, 0);
      this._checkMaxDate(value);
    },
    _checkMaxDate(value) {
      if (this.maxDate) {
        const tempMaxDate = this.maxDate;
        tempMaxDate.setHours(0, 0, 0, 0);
        if (value.getTime() > tempMaxDate.getTime()) {
          this.selectedDate = null;
        } else {
          this.selectedDate = value;
        }
      } else {
        this._checkMinDate(value);
      }
    },
    _checkMinDate(value) {
      if (this.minDate) {
        const tempMinDate = this.minDate;
        tempMinDate.setHours(0, 0, 0, 0);
        if (value.getTime() < tempMinDate.getTime()) {
          this.selectedDate = null;
        } else {
          this.selectedDate = value;
        }
      } else {
        this.selectedDate = value;
      }
    },
    openCalendar() {
      this.isCalendarOpen = true;
      this.selectedDate = this.value;
    },
    closeCalendar() {
      this.isCalendarOpen = false;
    },
    submit() {
      this.$emit('input', this.selectedDate);
      this.closeCalendar();
    },
    decideErrorField() {
      if (this.isError) {
        document.querySelector(`.${this.name}`).querySelector('input').classList.add('error');
      } else {
        document.querySelector(`.${this.name}`).querySelector('input').classList.remove('error');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
