<template>
  <div
    class="modal-lp"
    @click="clickOutside"
  >
    <div
      :id="idCustom"
      class="modal-lp__content"
      :style="width ? 'width: ' + width : ''"
    >
      <div
        v-show="useIconClose"
        class="modal-lp__content__close"
        :class="{'close-custom': closeCustom}"
        @click="closeModal()"
      >
        <img
          :src="customIconClose ? `/images/${customIconClose}` : '/images/close_modal.svg'"
          alt="close.png"
        >
      </div>
      <header
        v-if="header"
        class="modal-lp__content__header"
      >
        <slot name="header" />
      </header>
      <section class="modal-lp__content__body">
        <slot />
      </section>
      <footer
        v-if="footer"
        class="modal-lp__content__footer"
      >
        <slot name="footer" />
      </footer>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'Modal',
  mixins: [mixin],
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '',
    },
    closeCustom: {
      type: Boolean,
      default: false,
    },
    useIconClose: {
      type: Boolean,
      default: true,
    },
    idCustom: {
      type: String,
      default: '',
    },
    customIconClose: {
      type: String,
      default: "",
    },
  },
  methods: {
    clickOutside(e) {
      if (e.target.classList[0] === 'modal-lp') {
        this.$emit('clickOutside')
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';
@import 'src/assets/css/style.scss';

.modal-lp {
  @include overlay();

  &__content {
    @include content();

    &__close {
      text-align: center;
      font-weight: bold;
      position: absolute;
      top: 6px;
      right: 12px;
      cursor: pointer;
    }

    &__header {
      border-bottom: 1px solid $color-gray-pensive-5;
    }

    &__body {
      padding: 21px 15px 18px 24px;
    }
  }

}
</style>
