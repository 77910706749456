export default  {
  created() {
    if (!this.isSsr()) {
      document.documentElement.style.overflow = 'hidden';
      document.body.scroll = 'no';
    }
  },
  beforeDestroy() {
    document.documentElement.style['overflow-y'] = 'visible';
    document.body.scroll = 'yes';
  },
  methods: {
    closeModal() {
      this.$emit('close');
      document.documentElement.style['overflow-y'] = 'visible';
      document.body.scroll = 'yes';
    },
  },
}
